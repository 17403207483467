import { Link, graphql } from "gatsby";

import Layout from "../components/layout-main";
import Img from "gatsby-image";

/** @jsx jsx */
import { jsx } from "theme-ui";

const BlogIndex = ({ data, location }) => {
  const posts = data.allMarkdownRemark.nodes;

  return (
    <Layout location={location} pageTitle="Blog">
      <section>
        <div className="aw-content">
          <ol style={{ listStyle: `none` }}>
            {posts.map((post) => {
              const title = post.frontmatter.title || post.fields.slug;
              const subtitle = post.frontmatter.subtitle || "";
              const featuredImgFluid =
                post.frontmatter.featuredImage?.childImageSharp.fluid;

              return (
                <article
                  key={post.fields.slug}
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <h2>
                      <Link to={"/blog" + post.fields.slug} itemProp="url">
                        <span itemProp="headline">{title}</span>
                      </Link>
                    </h2>
                    <h4 sx={{ marginTop: "0", fontWeight: "400" }}>
                      <span itemProp="headline">{subtitle}</span>
                    </h4>
                    {featuredImgFluid && (
                      <Link to={"/blog" + post.fields.slug} itemProp="url">
                        <Img
                          sx={{ maxWidth: "400px" }}
                          fluid={featuredImgFluid}
                        />
                      </Link>
                    )}
                  </header>
                  <div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: post.frontmatter.description || post.excerpt,
                      }}
                      itemProp="description"
                    />
                  </div>
                  <small>Published on {post.frontmatter.date}</small>
                </article>
              );
            })}
          </ol>
        </div>
      </section>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fields: { slug: { nin: ["/terms/", "/privacy/", "/security/", "/esign/"] } }
      }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          subtitle
          description
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`;
